a.btn-arrow {
  font-weight: 700;
  font-size: 16px;
}
a.btn-arrow:after {
  content: " ";
  width: 2.2em;
  height: 1.2em;
  vertical-align: middle;
  background: url("../img/right-arrow.svg") no-repeat center;
  background-size: contain;
  display: inline-block;
  margin-left: 20px;
}

.authorsWrap {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
}

.authorBox {
  position: relative;
  width: calc(25% - 25px);
  display: block;
  margin: 0 auto auto;
  margin-bottom: 70px;
}
.authorBox h3 {
  font-size: 22px;
  margin-bottom: 12px;
}
.authorBox p {
  height: 4em;
  overflow: hidden;
  position: relative;
}
.authorBox p:before {
  content: " ";
  position: absolute;
  box-shadow: inset 0px -41px 23px -24px #ffffff;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 39px;
}
.authorBox .authorImage {
  position: relative;
  width: 100%;
  padding-top: 25px;
  padding-left: 25px;
  transition: all 150ms ease-in-out;
  margin-bottom: 30px;
}
.authorBox .authorImage img {
  position: relative;
  z-index: 5;
  width: calc(100% - 25px);
  width: 100%;
  height: auto;
  transition: all 150ms ease-in-out;
  transform: translate(-25px, -25px);
  box-shadow: 5px 5px 14px rgba(0, 0, 0, 0.45);
}
.authorBox .authorImage:before {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  width: calc(100% - (2 * 25px));
  padding-top: calc(100% - (2 * 25px));
  border: 7px solid #917164;
  transition: all 250ms ease-in-out;
}
.authorBox:hover .authorImage img {
  transform: translate(-25px, -25px) scale(0.95);
}
.authorBox:hover .authorImage:before {
  /*width: 100%;
  height: 100%;*/
  background: #C24D41;
  border-color: #C24D41;
  box-shadow: 5px 5px 14px rgba(0, 0, 0, 0.45);
}

/* Images */
.artWrapper {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
}

/*
.artWrapperHP {
	display: flex;
	flex-wrap: nowrap;
	.right-col,
	.left-col {
		width: 50%;
	}
	.left-col {
		display: flex;
		flex-wrap: wrap;
		padding-right: 80px;
		.artBox {
			width: 38%;
			flex-grow: 1;
		}
	}
	.right-col {
		margin: auto;
	}
}*/
.artBox {
  width: calc(25% - 25px);
  margin: 0 auto 30px;
  position: relative;
  transition: all 190ms ease-in-out;
}
.artBox.sold {
  opacity: 0.6;
}
.artBox.sold .art-price {
  color: #c60707;
}
.artBox.sold:hover {
  opacity: 1;
}
.artBox .artListCont {
  padding: 0 12px 16px;
}
.artBox h3 {
  font-size: 22px;
  margin-bottom: 7px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.artBox .artImageWrap {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 380px;
  background: #dcdcdc;
  margin-bottom: 10px;
  padding: 30px;
  position: relative;
}
.artBox img {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
}
.artBox:hover {
  transform: translate(0, -6px);
  box-shadow: 0px 16px 26px -7px rgba(0, 0, 0, 0.28);
}

.art__badge {
  position: absolute;
  background-color: #C24D41;
  top: 0;
  right: 0;
  padding: 8px 16px;
  color: #fff;
  font-weight: 700;
}

.artWrapperHP .artBox {
  margin: 0 20px 40px 0;
}

.author-text {
  display: flex;
  align-items: center;
}
.author-text .description {
  padding-right: 40px;
}
.author-text h2 {
  font-size: 50px;
}

img.authorWelcomePhoto {
  max-width: 100%;
  width: auto;
  height: auto;
  max-height: 450px;
  margin: auto;
  margin-bottom: 60px;
  display: block;
}

.amazInfo {
  width: 390px;
  max-width: 100%;
  flex-shrink: 0;
  background: #f3f3f3;
  padding: 26px;
  margin-bottom: 24px;
}
.amazInfo p {
  font-size: 16px;
}
.amazInfo p:last-child {
  margin-bottom: 0;
}

.artFull {
  display: flex;
}
.artFull .imagesCol {
  width: 40%;
}
.artFull .textCol {
  padding-left: 30px;
  width: 60%;
}

.artImage {
  padding: 50px;
  background: #dcdcdc;
  margin-bottom: 20px;
  position: relative;
}
.artImage img {
  width: 100%;
}

.artGallery {
  margin-bottom: 20px;
}
.artGallery a {
  padding: 12px;
  display: inline-block;
  margin-right: 6px;
  background: #dcdcdc;
}
.artGallery a img {
  height: 130px;
  width: auto;
}

.art-detail__phone-email {
  display: flex;
  gap: 16px;
  justify-content: space-between;
  flex-wrap: wrap;
}
.art-detail__phone-email a {
  display: block;
  background-color: #f3f3f3;
  padding: 24px 36px;
  font-weight: 700;
  font-size: 22px;
}
@media (max-width: 1200px) {
  .art-detail__phone-email a {
    font-size: 20px;
  }
}
@media (max-width: 900px) {
  .art-detail__phone-email a {
    font-size: 18px;
  }
}

form {
  margin: 30px 0;
}
form input[type=text] {
  width: 50%;
  margin: 0 10px 20px 0;
  border: 1px solid #161616;
  padding: 16px;
}
form input[type=text]:nth-child(even) {
  margin-right: 0;
  width: calc(50% - 15px);
}
form textarea {
  width: 100%;
  border: 1px solid #161616;
  padding: 16px;
  margin-bottom: 20px;
}

section {
  padding: 0 60px 0 100px;
}

/* Banner */
.banner {
  display: flex;
  align-items: center;
  margin: 0 -60px 0 -100px;
  width: auto;
  min-width: 100%;
}
.banner .left-site,
.banner .right-site {
  width: 50%;
  position: relative;
}
.banner .left-site {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  min-height: 100vh;
  padding: 110px;
}
.banner .right-site {
  overflow: hidden;
}
.banner .right-site .banner__slick-wrapper {
  padding: 110px 130px 110px 110px;
}
.banner .right-site .banner__slide {
  height: 600px;
}
@media (max-width: 650px) {
  .banner .right-site .banner__slide {
    height: 300px;
  }
}
.banner .right-site .banner__image {
  height: 100%;
  width: 100%;
}
.banner .right-site .banner__image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}
.banner .right-site .slick-dots {
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 10px 0;
  margin-top: 16px;
  list-style-type: none;
}
.banner .right-site .slick-dots li {
  margin: 0 0.25rem;
}
.banner .right-site .slick-dots button {
  display: block;
  width: 10px;
  height: 10px;
  padding: 0;
  border: none;
  border-radius: 100%;
  background-color: #e3e3e3;
  text-indent: -9999px;
}
.banner .right-site .slick-dots li.slick-active button {
  background-color: #000;
}

.alterAfter {
  content: "";
  position: absolute;
  width: 30%;
  height: 100%;
  background: #C24D41;
  right: 0;
  top: 0;
  z-index: -1;
}
@media (max-width: 650px) {
  .alterAfter {
    width: 80%;
    height: 75%;
  }
}

body.home {
  padding-top: 0;
}

/* Scroll stuff */
.scrollDown {
  display: inline-block;
  width: 1.2em;
  height: auto;
  writing-mode: vertical-lr;
  position: absolute;
  right: 10%;
  bottom: 10%;
  color: #fff;
  text-transform: uppercase;
}
.scrollDown::before {
  animation: elasticus 2.9s cubic-bezier(1, 0, 0, 1) infinite;
  position: absolute;
  bottom: 110%;
  left: 50%;
  margin-left: -1px;
  width: 2px;
  height: 170px;
  background: #fff;
  content: " ";
}

@keyframes elasticus {
  0% {
    transform-origin: 0% 0%;
    transform: scale(1, 0);
  }
  50% {
    transform-origin: 0% 0%;
    transform: scale(1, 1);
  }
  50.1% {
    transform-origin: 0% 100%;
    transform: scale(1, 1);
  }
  100% {
    transform-origin: 0% 100%;
    transform: scale(1, 0);
  }
}
.smallBox {
  padding: 45px;
  width: 800px;
  margin: 0 auto 40px;
  background: #eee;
  max-width: 100%;
}

iframe {
  width: 100%;
  height: 350px;
}

body.home h2 {
  text-align: center;
}
body.home .btn-std {
  margin: 0 auto;
}
body.home .banner .btn-std {
  margin-left: 0;
}

.banner {
  margin-bottom: 100px;
}

.artWrapper {
  margin-bottom: 100px;
}

img.blur-up {
  filter: blur(8px);
}

img.blur-up.lazyloaded {
  filter: blur(0);
}

.exhibition {
  width: 700px;
  max-width: 100%;
  margin: 25px auto 40px;
  padding: 20px;
  border: 2px solid lightgray;
  box-shadow: 0 7px 11px rgba(0, 0, 0, 0.2);
}

.dateIcon,
.timeIcon {
  margin-right: 15px;
}

.dateIcon:before,
.timeIcon:before {
  content: " ";
  display: inline-block;
  width: 0.8em;
  height: 0.8em;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  margin-right: 6px;
  vertical-align: middle;
}

.dateIcon:before {
  background-image: url("../img/calendar.svg");
}

.timeIcon:before {
  background-image: url("../img/clock.svg");
}

@media (max-width: 1400px) {
  .banner h1 {
    font-size: 65px;
  }
}
@media (max-width: 1200px) {
  .authorBox,
  .artBox {
    width: calc(33% - 25px);
  }
  .authorBox:last-of-type,
  .artBox:last-of-type {
    display: none;
  }
  .banner h1 {
    font-size: 50px;
  }
}
@media (max-width: 992px) {
  .authorBox,
  .artBox {
    width: calc(50% - 25px);
  }
  .authorBox:last-of-type,
  .artBox:last-of-type {
    display: block;
  }
  .banner {
    flex-wrap: wrap;
  }
  .banner .left-site,
  .banner .right-site {
    width: 100%;
  }
  .banner .right-site {
    order: -1;
    margin: 70px 0 0 0;
  }
  .banner .right-site .banner__slick-wrapper {
    padding: 55px 16px;
  }
  .banner .left-site {
    min-height: 0;
    padding: 24px;
  }
}
@media (max-width: 900px) {
  body.home h2 {
    font-size: 50px;
  }
}
@media (max-width: 750px) {
  .banner {
    margin: 0 -10px 70px;
  }
  body.home h2 {
    font-size: 50px;
  }
  .author-text {
    flex-wrap: wrap;
  }
}
ol.breadcrumb {
  margin-bottom: 10px;
}

@media (max-width: 650px) {
  .authorBox,
  .artBox {
    width: 100%;
    margin-right: 0 !important;
  }
  .banner {
    flex-wrap: wrap;
  }
  .banner .right-site .banner__slick-wrapper {
    padding: 55px 80px 55px 16px;
  }
  .banner .left-site {
    width: 100%;
    padding: 20px;
  }
  .banner .left-site h1 {
    width: 100%;
    font-size: 40px;
    text-align: center;
  }
  .banner .left-site p {
    text-align: center;
  }
  .banner .left-site .btn-std {
    margin: 20px auto !important;
  }
  ol.breadcrumb {
    display: none;
  }
  .smallBox {
    background: transparent;
    padding: 20px 0;
  }
  body.home h2 {
    font-size: 30px;
  }
}
@media (max-width: 550px) {
  section {
    padding: 0 10px 0 10px;
  }
}
#footer .navigation {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 45px;
}
#footer .navigation li a {
  display: block;
  padding: 15px 30px;
  text-align: center;
  font-weight: 700;
}
#footer .navigation li a:hover {
  color: #C24D41;
}

@media (max-width: 650px) {
  #footer .navigation li {
    width: 100%;
  }
  .author-text h2 {
    font-size: 30px;
  }
}
@media (max-width: 950px) {
  .artFull {
    flex-wrap: wrap;
  }
  .artFull .imagesCol {
    width: 100%;
  }
  .artFull .imagesCol .artImage {
    display: none;
  }
  .artFull .textCol {
    width: 100%;
    padding: 0;
  }
}
.socials {
  position: fixed;
  top: 50%;
  right: 0;
  width: 60px;
  padding: 16px;
  background: #fff;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  transform: translate(0, -50%);
  z-index: 20;
  box-shadow: -4px 4px 8px rgba(0, 0, 0, 0.15);
}
.socials a {
  display: inline-block;
  margin-bottom: 9px;
  width: 100%;
}

@media (max-width: 650px) {
  .socials {
    width: 38px;
    padding: 7px;
  }
}
span.showEle {
  cursor: pointer;
}

span.showMe {
  display: block;
  /* position: absolute; */
  right: 0;
  top: 100%;
  background: #fff;
  font-size: 17px;
  font-weight: 700;
  writing-mode: vertical-lr;
  margin: 10px auto 0;
}

#contactForm_forms_flash .alert,
#genericForm_forms_flash .alert {
  color: #155724;
  background-color: #d4edda;
  padding: 10px;
  margin: 20px 0;
  text-align: center;
}
#contactForm_forms_flash .alert p,
#genericForm_forms_flash .alert p {
  margin: 0;
}
#contactForm_forms_flash .alert li,
#genericForm_forms_flash .alert li {
  margin: 5px auto;
}
#contactForm_forms_flash .alert ul,
#genericForm_forms_flash .alert ul {
  list-style-type: disc;
  display: flex;
  flex-direction: column;
  margin: 5px;
}
#contactForm_forms_flash .alert.alert-danger,
#genericForm_forms_flash .alert.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
}
#contactForm_forms_flash .alert .close,
#genericForm_forms_flash .alert .close {
  display: none;
}

.w-100 {
  width: 100%;
}

input#email[name=Email] {
  margin-right: 20px !important;
}

.form-mail-phone input#email,
.form-mail-phone input#telefon {
  width: 50%;
}
.form-mail-phone input#telefon {
  width: calc(50% - 25px);
}

input#name {
  width: 100%;
}

@media (max-width: 767px) {
  input#email[name=Email],
  input#name {
    width: 100%;
    margin-right: 0;
  }
}